import { json, useMatches } from "@remix-run/react";
import { v4 as uuid } from "uuid";
import { z } from "zod";

import type { LoaderData } from "~/root";

import { csrfTokenCookie } from "./cookies.server";

export function createCSRFToken() {
  return uuid();
}

export async function readCSRFToken(request: Request) {
  let value = await csrfTokenCookie.parse(request.headers.get("Cookie"));
  let result = z.string().uuid().safeParse(value);
  if (!result.success) return null;
  return result.data;
}

export function validateCSRFToken(csrf: string, formData: FormData) {
  let token = formData.get("csrf");
  if (!token) throw json("Invalid CSRF token", 422);
  if (csrf !== token) throw json("Invalid CSRF token", 422);
}

export async function setCSRFToken(token: string, headers: Headers) {
  headers.append("Set-Cookie", await csrfTokenCookie.serialize(token));
}

export function CSRFInput() {
  let [rootMatch] = useMatches();
  let data = rootMatch.data as LoaderData;
  return <input type="hidden" name="csrf" value={data.csrf ?? ""} />;
}
